import {
  // Container,
  Text,
  // Divider,
  Box,
  Image,
  Button,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
// import { FiShoppingCart, FiShoppingBag } from "react-icons/fi"
import ThreeD from "./ThreeD";
import altimage from "../../../src/altimage.png";
import { Link } from "react-router-dom";
const GridView = ({ products, baseUrl, tenant_code }) => {
  return (
    <div>
      <Box mt={4} marginBottom="-125px">
        <SimpleGrid
          minChildWidth="300px"
          align="center"
          justify="center"
          spacing="40px"
          mb={32}
        >
          {products.map((product, index) => (
            <Box
              bg="white"
              maxW="sm"
              marginLeft="17px"
              borderWidth="1px"
              rounded="lg"
              shadow="lg"
              _hover={{ shadow: "dark-lg" }}
              key={index}
            >
              <Image
                h="300px"
                w="400px"
                fit="fill"
                src={product.image == null ? { altimage } : product.image}
                alt=""
                roundedTop="lg"
              />
              {/* <Image h="300px" fit="cover" src={product.images[0].url} alt={`Picture of ${product.name}`} roundedTop="lg" /> */}
              <Box p="6">
                <Flex
                  mt="1"
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Text
                    fontSize="2xl"
                    fontWeight="600"
                    as="h4"
                    lineHeight="tight"
                    fontFamily="Bold 700"
                    h="60px"
                    color="#2B6CB0"
                  >
                    {product.title}
                    {/* {product.name} */}
                  </Text>
                </Flex>

                <Text
                  mt={2}
                  h="200px"
                  color="gray.500"
                  display={{ base: "none", md: "flex" }}
                  className="description-text"
                  fontFamily="Regular 400"
                >
                  {product.description}
                </Text>
                {/* <Link to ="/productdetail">
                                    <Button>Details</Button>
                                </Link> */}

                {/* <Text as="h4" fontSize="2xl" fontWeight="bold" color="teal.600" >
                                    ${product.price / 100}
                                </Text> */}
                <a
                  className="model-button"
                  href={
                    product.pid == null
                      ? `${baseUrl}${product.assetId}?tenantcode=${tenant_code}`
                      : `${baseUrl}${product.pid}?tenantcode=${tenant_code}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    leftIcon={<ThreeD size="24px" />}
                    size="lg"
                    mt={4}
                    isFullWidth
                    colorScheme="blue"
                    variant="outline"
                    alignSelf={"center"}
                    className="snipcart-add-item"
                  >
                    {/* <a className='model-button' href={`${baseUrl}?id=${product.id}`} target='_blank' rel="noreferrer"> */}
                    View Model
                  </Button>
                </a>

                {/* <Button leftIcon={<FiShoppingCart size="24px" />} size="lg" mt={4} isFullWidth colorScheme="blue" variant="outline" alignSelf={"center"} className="snipcart-add-item" onClick={(e) => onClick(e, index)} >
                                    Add to Cart
                                </Button> */}
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </div>
  );
};

export default GridView;
