import { useState, useEffect } from "react";
import "./App.css";
import Homepage from "./components/homepage/Homepage";
// import Cart from "./components/cart/Cart";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Productdetail from "./components/homepage/Productdetail";
import images from "./components/homepage/images";

function App() {
  let baseUrl = "https://view.zdviewer.com/products/";

  let curPage = window.location.hostname;
  if (curPage.includes("staging")|| curPage.includes("localhost")) {
    baseUrl = "https://staging.view.zdviewer.com/products/"; //"https://zdviewerweb.z13.web.core.windows.net/";
  } else {
    baseUrl = "https://view.zdviewer.com/products/"; 
  }

  const [publicProducts, setPublicProducts] = useState([]);
  const [grid, setGrid] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const res = await fetchPublicData();
      setPublicProducts(res);
    };
    getData();
  }, []);

  async function fetchPublicData() {
    const res = await fetch(
      "https://assets.zdviewer.com/zdviewer/Catalog/db.json"
    );
    const response = await res.json();
    // console.log(response.data)
    return response.data;
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Homepage
                publicProducts={publicProducts}
                grid={grid}
                setGrid={setGrid}
                baseUrl={baseUrl}
              />
            }
          />

          <Route
            // path="/tenantcode/:tenantcode"
            path="/:tenantcode"
            exact
            element={
              <Homepage
                publicProducts={publicProducts}
                grid={grid}
                setGrid={setGrid}
                baseUrl={baseUrl}
              />
            }
          />
          <Route
            path="/productdetail"
            element={<Productdetail images={images} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

// :::: USE WHEN CART FEATURE IS REQUIRED
// const [cart, setCart] = useState([]);
// const addToKart = (e, index) => {
//   // console.log(products[index])
//   setCart([...cart, products[index]])
//   // console.log(cart)
// }

// {/* onClick={addToKart} */ }
