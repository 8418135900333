import { useState, useEffect } from "react";
import {
  Container,
  Text,
  Divider,
  Flex,
  // Box, Image, Button, SimpleGrid
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip, faList } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import GridView from "./GridView";
import ListView from "./ListView";

import "./style.css";

function Homepage({
  publicProducts,
  // onClick,
  setGrid,
  grid,
  baseUrl,
}) {
  const [tenantResponse, setTenantResponse] = useState({
    data: {
      tenantName: "",
    },
  });
  // console.log(tenantResponse.data.tenantName)

  // fetch the url params
  const { tenantcode } = useParams();
  // console.log(tenantcode)

  // fetch Private Products
  const [privateProducts, setPrivateProducts] = useState([]);
  const screenSize = 500;

  useEffect(() => {
    const getData = async () => {
      const res = await fetchPrivateData(tenantcode);
      // console.log(res)
      debugger;
      setPrivateProducts(res.resData);
      setTenantResponse(res.resTenant);
    };

    getData();
  }, [tenantcode]);

  async function fetchPrivateData(tenant_code) {
    let resData = [];
    let resTenant = {};
    debugger;
    if (tenant_code === undefined) tenant_code = "";
    const targetUrl = `https://zdservice.azurewebsites.net/ClientApi/GetTenantProducts?TenantCode=${tenant_code}`;
    const res = await fetch(targetUrl);
    const response = await res.json();
    if (response.isSuccess) {
      resData = response.data.products;
      resTenant = response;
    } else {
      resData = [];
      resTenant = {
        data: {
          tenantName: "ZiniosEdge Catalog",
        },
      };
    }
    // console.log(response)
    return { resData, resTenant };
  }

  // console.log(window.innerWidth)
  return (
    <Container maxW="container-2xl" h="100%" bg="#F9F9F9">
      <Flex
        justifyContent="space-between"
        width="103%"
        alignContent="left"
        bg="white"
        marginLeft="-0.875rem"
      >
        <Text
          as="a"
          // href="/"
          fontSize="2rem"
          color="#2B6CB0"
          fontFamily="Robo"
          my="0.188rem"
          marginLeft="0.5rem"
          backgroundColor="white"
        >
          {tenantcode ? tenantResponse.data.tenantName : "ZiniosEdge Catalog"}
        </Text>

        {window.innerWidth >= screenSize && (
          <div className="icons-container">
            <button className="icons" onClick={() => setGrid(true)}>
              <FontAwesomeIcon icon={faGrip} size="lg" />
            </button>
            <button className="icons" onClick={() => setGrid(false)}>
              <FontAwesomeIcon icon={faList} size="lg" />
            </button>
          </div>
        )}

        {/* <Button
          my="5px"
          colorScheme="green"
          variant="ghost"
          leftIcon={<FiShoppingBag size="24px" />}
          size="lg"
          p={2}
          className="snipcart-checkout"
        >
          <Link to='/cart' >View Cart</Link>
        </Button> */}
      </Flex>
      <Divider />
      {window.innerWidth >= screenSize ? (
        grid ? (
          <GridView
            products={tenantcode ? privateProducts : privateProducts}
            baseUrl={baseUrl}
            tenant_code={tenantcode}
          />
        ) : (
          <ListView
            products={tenantcode ? privateProducts : privateProducts}
            baseUrl={baseUrl}
            tenant_code={tenantcode}
          />
        )
      ) : (
        <GridView
          products={tenantcode ? privateProducts : privateProducts}
          baseUrl={baseUrl}
          tenant_code={tenantcode}
        />
      )}
    </Container>
  );
}

export default Homepage;
