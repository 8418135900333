
const images = [
    {
      id: 1,
      src:"https://www.tvsmotor.com/-/media/Images/ecommerce-product/Accessories/U368/Cover-Engine-Sprocket/Cover-Engine-Sprocket1.jpg",
      alt: "The world"
    },
    {
      id: 2,
      src:
        "https://www.tvsmotor.com/-/media/Images/ecommerce-product/Accessories/U368/Cover-Engine-Sprocket/Cover-Engine-Sprocket2.jpg",
      alt: "Train"
    },
    {
      id: 3,
      src:
        "https://www.tvsmotor.com/-/media/Images/ecommerce-product/Accessories/U368/Cover-Engine-Sprocket/Cover-Engine-Sprocket3.jpg",
      alt: "Laptop"
    }
  ];
  export default images;