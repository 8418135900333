import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube } from '@fortawesome/free-solid-svg-icons'

const ThreeD = ({ size }) => {
    return (
        <>
            {/* <img src="https://cdn-icons-png.flaticon.com/512/44/44218.png" width={size}/> */}
            <FontAwesomeIcon icon={faCube} size="lg" />
        </>
    )
}

export default ThreeD