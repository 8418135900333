import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./Productdetail.css"
import { useRef } from "react";

export default function Productdetail({ images }) {
  const carousel = useRef();
  const settings = {
    infinite: true,
    customPaging: function (i) {
      return (
        <a>
          <img
            src={images[i].src}
           style={{height:"15vh", Width:"100%"}}
            alt={images[i].alt}
          />
        </a>
        
      );
    },
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: true,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    lazyLoad: true
  };
  console.log(images);

  return (
    <div className="container" style={{height:"130vh", width:"100%", marginTop:"40px", backgroundColor:"#F9F9F9"}}>
        <div className='row' >
        <div className='col-2' >

    <Slider {...settings} ref={carousel} style={{marginLeft:"54px", marginTop:"54px"}}>
      {images.map((item) => (
        <div key={item.id}>
          {" "}
          <img src={item.src} alt={item.alt} style={{height:"80vh"}} />
        </div>
      ))}
    </Slider>
  </div>
  <div className="col-2">
    <br/>
    <br/>
    <div className="enginecover"><h7>Engine Cover</h7></div>
    <div className="price-col">Rs 300<sub>Price inclusive of all taxes.</sub></div>
    <br/>
    <div className="quantity-col" style={{fontsize:"20px"}}>Quantity</div>
    <button1><span>-</span><span>1</span><span>+</span></button1>
    <br/>
    <button2 type="submit">ADD TO CART</button2>
    <button3 type="submit">BUY NOW</button3>
    <div className="product_details">Product Details</div>
    <div className="desc-col">A piece of jewellery to the heart of your machine, this cover engine sprocket is Powder coated and machined for durability and style.</div>
  </div>
  
  </div>
  </div>
  );
}