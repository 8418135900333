import {
    // Container,
    Text,
    // Divider,
    Box,
    Image,
    Button,
    SimpleGrid,
    Flex,
} from "@chakra-ui/react";
// import { FiShoppingCart, FiShoppingBag } from "react-icons/fi"
import ThreeD from './ThreeD'

const ListView = ({ products, baseUrl, tenant_code }) => {
    return (
        <div>
            <Box mt={4} marginBottom="-350px">
                <SimpleGrid
                    // minChildWidth="300px"
                    align="center"
                    justify="center"
                    spacing="40px"
                    mb={32}
                    columns={1}
                >
                    {products.map((product, index) => (

                        <Flex bg="white" h="300px" maxW="100%" borderWidth="1px" rounded="lg" shadow="lg" _hover={{ shadow: "dark-lg" }} key={index} >
                            <a href="https://www.tvsmotor.com/shop/accessories/accessories-details?q=dcc0daa16e6abdfaafea1a50ccbcfe57">
                            <Image h="300px" w="300px" fit="fill" src={product.image == null ? "https://digital.hammacher.com/Items/98004/98004_1000x1000.jpg" : product.image} alt="" roundedTop="lg" />
                            </a>

                            {/* <Image h="300px" w="300px" fit="cover" src={product.images[0].url} alt={`Picture of ${product.name}`} roundedTop="lg" /> */}

                            <Flex w="50%" mr="10" ml="10">
                                <Box p="6">
                                    <Text fontSize="2xl" mb="10" mt="5" as="h4" lineHeight="tight" fontFamily="Roboto" className="title-text" >
                                        {product.title}
                                        {/* {product.name} */}
                                    </Text>

                                    <Text mt={2} h="300px" color="gray.500" display={{ base: "none", md: "flex" }} className='description-text' >
                                        {product.description}
                                    </Text>
                                </Box>
                            </Flex>

                            {/* <Text as="h4" fontSize="2xl" fontWeight="bold" color="teal.600" >
                                    ${product.price / 100}
                                </Text> */}

                        <a style={{height:"0px"}} className='model-button' href={product.pid == null ? `${baseUrl}?id=${product.assetId}&tenantcode=${tenant_code}` : `${baseUrl}?id=${product.pid}&tenantcode=${tenant_code}`} target='_blank' rel="noreferrer">
                            <Button marginLeft="1rem" leftIcon={<ThreeD size="24px" />} size="lg" mt={100} isFullWidth colorScheme="blue" variant="outline" alignSelf={"center"} className="snipcart-add-item" >
                                {/* <a className='model-button' href={`${baseUrl}?id=${product.id}`} target='_blank' rel="noreferrer"> */}
                             View Model
                            </Button>
                        </a>
                            


                            {/* <Button leftIcon={<FiShoppingCart size="24px" />} size="lg" mt={4} isFullWidth colorScheme="blue" variant="outline" alignSelf={"center"} className="snipcart-add-item" onClick={(e) => onClick(e, index)} >
                                    Add to Cart
                                </Button> */}

                        </Flex>
                    ))}
                </SimpleGrid>
            </Box>
            
        </div>
    )
}

export default ListView